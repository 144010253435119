<template>
  <div>
    <div class="registerForm">
      <p class="title">{{ shouJiHaoMa }}</p>
      <div class="phone">
        <div class="phone-1">
          <span @click="selectPhone">+{{ phoneCode }}</span>
          <i></i>
          <b class="line"></b>
          <div class="phoneBox" :class="{ active: phoneShow }">
            <p @click="selectCode(852)">+852</p>
            <p @click="selectCode(823)">+823</p>
          </div>
        </div>
        <input
          @input="changeBtn"
          v-model.trim="phone"
          type="number"
          :placeholder="new_ZC_shuRuShouJiHao"
        />
      </div>
      <p class="title">{{ yanZhengMa }}</p>
      <div class="code">
        <input
          @input="changeBtn"
          v-model.trim="code"
          type="number"
          :placeholder="new_ZC_shuRuYanZhengMa"
        />
        <span :class="{ active: codeBtn }" @click="sendCode">{{
          codeBtn ? new_ZC_huoQuYanZhengMa : new_ZC_chongXinFaSong + time
        }}</span>
      </div>
      <p class="title">
        {{ jieShouKFC }}
      </p>
      <div class="callPhone">
        <input
          v-model.trim="callPhone"
          type="text"
          :placeholder="new_ZC_shuRuDianYou"
        />
      </div>
      <p class="agreement">
        <i
          class="agreeOne"
          :class="{ active: agreeOne }"
          @click="selectCheckOne"
        ></i
        ><span>{{ noJieShouKFC }}</span>
      </p>
      <p class="agreement">
        <i
          class="agreeTwo"
          :class="{ active: agreeTwo }"
          @click="selectCheckTwo"
        ></i
        ><span
          >{{ zhengCeXieXi }} <b @click="jumpYS">《{{ new_ZC_yiSiZhengCe }}》</b
          >{{ new_ZC_ji
          }}<b @click="jumpAgrment">《{{ new_ZC_tiaoKuanXiZe }}》</b></span
        >
      </p>
      <div class="nextBtn" :class="{ active: btnShow }" @click="nextMove">
        {{ xiaYiBu }}
      </div>
    </div>

    <confirm-box
      v-show="showMsg"
      :title="ZC_msgOne"
      :subTitle="ZC_msgTwo"
      :subTitleShow="true"
      :size="16"
      :finishBtn="finishBtn"
      @finish="finish"
    ></confirm-box>
  </div>
</template>

<script>
import ConfirmBox from "./common/CnfirmBox.vue";
import language from "../assets/js/language.js";
import { postPaas } from "../assets/utils/request";

export default {
  name: "RegisterPhone",
  data() {
    return {
      btnShow: false,
      codeBtn: true,
      agreeOne: false,
      agreeTwo: false,
      time: 60, //倒计时
      code: "",
      phone: "",
      callPhone: "",
      showMsg: false,
      phoneShow: false,
      phoneCode: "852",
      // 静态文字
      shouJiHaoMa: "",
      yanZhengMa: "",
      jieShouKFC: "",
      jieShouKFCText: "",
      noJieShouKFC: "",
      zhengCeXieXi: "",
      xiaYiBu: "",
      new_ZC_shuRuShouJiHao: "",
      new_ZC_shuRuYanZhengMa: "",
      new_ZC_huoQuYanZhengMa: "",
      new_ZC_chongXinFaSong: "",
      new_ZC_shuRuDianYou: "",
      new_ZC_yiSiZhengCe: "",
      new_ZC_ji: "",
      new_ZC_tiaoKuanXiZe: "",
      youXiaoDiZhi: "",
      youXiaoShouJiHao: "",
      ZC_msgOne: "",
      ZC_msgTwo: "",
      HQXXSB: "",
      finishBtn: this.$store.state.language == 1 ? "確認" : "Confirm",
    };
  },
  methods: {
    jumpYS() {
      var url = "https://myapp.kfc.com.hk/c/privacy_tc.html";
      if (this.$store.state.language != 1) {
        url = "https://myapp.kfc.com.hk/c/privacy_en.html";
      }
      window.location.href = url;
    },
    selectPhone() {
      // this.phoneShow = !this.phoneShow;
    },
    selectCode(type) {
      this.phoneCode = type;
      this.selectPhone();
    },
    changeBtn() {
      if (this.phone.length > 8) {
        this.phone = this.phone.slice(0, 8);
      }
      if (!this.phone || this.code.length < 4 || !this.agreeTwo) {
        return (this.btnShow = false);
      }
      this.btnShow = true;
    },
    finish() {
      this.$router.back(-1);
    },
    async sendCode() {
      if (!this.phone) {
        return this.$toast(this.youXiaoShouJiHao);
      }
      if (this.phone.length != 8) {
        return this.$toast(this.youXiaoShouJiHao);
      }
      if (!this.codeBtn) {
        return;
      }

      var params = {
        actionName: "candao.member.register",
        content: {
          areaCode: this.phoneCode,
          phone: this.phone,
        },
      };
      let result = await postPaas("UserUnify", params);
      if (result.status == 1) {
        this.codeBtn = false;
        var timer = setInterval(() => {
          if (this.time <= 0) {
            clearInterval(timer);
            this.time = 60;
            this.codeBtn = true;
            this.new_ZC_huoQuYanZhengMa = this.new_ZC_chongXinFaSong;
          }
          this.time--;
        }, 1000);
      } else {
        if (
          result.status == -1110 &&
          (result.msg ==
            "此電話號碼曾被用作登記KFC樂脆會會員，如你忘記密碼，我們可發送短訊至你的手機重設密碼" ||
            result.msg ==
              "This mobile number has been registered as KFC Crispy Fans Club before. If you forgot password, we can send you a SMS in order to reset password.")
        ) {
          this.showMsg = true;
        } else {
          this.$toast(this.HQXXSB);
        }
      }
    },
    async nextMove() {
      if (!this.agreeTwo && !this.btnShow) {
        return;
      }
      if (this.callPhone && this.callPhone.indexOf("@") < 0) {
        return this.$toast(this.youXiaoDiZhi);
      }
      var params = {
        actionName: "candao.member.registerVerifySms",
        content: {
          areaCode: this.phoneCode,
          phone: this.phone,
          smsCode: this.code,
        },
      };
      let result = await postPaas("UserUnify", params);
      if (result.status == 1) {
        var paramRes = {
          showCon: false,
          codeInfo: result.data,
          email: this.callPhone,
          phone:this.phone
        };
        this.$emit("move", paramRes);
      } else {
        this.$toast(this.HQXXSB);
      }
      // this.$emit("move", false);
    },
    selectCheckOne() {
      window.dataLayer.push({
        event: "agree_promotion",
      });
      this.agreeOne = !this.agreeOne;
      this.changeBtn();
    },
    selectCheckTwo() {
      this.agreeTwo = !this.agreeTwo;
      this.changeBtn();
    },
    jumpAgrment() {
      this.$router.push("/agreement");
    },
  },
  created() {
    var ykParam = localStorage.getItem("KFCYK");
    if (ykParam) {
      ykParam = JSON.parse(ykParam);
      this.phone = ykParam.phone || ykParam.phoneOne;
      if (ykParam.areaCode) this.phoneCode = ykParam.areaCode;
    }
    var languageType;
    if (this.$store.state.language == 1) {
      languageType = "chinese";
    } else {
      languageType = "english";
    }
    this.shouJiHaoMa = language.shouJiHaoMa[languageType];
    this.yanZhengMa = language.yanZhengMa[languageType];
    this.jieShouKFC = language.jieShouKFC[languageType];
    this.jieShouKFCText = language.jieShouKFCText[languageType];
    this.noJieShouKFC = language.noJieShouKFC[languageType];
    this.zhengCeXieXi = language.zhengCeXieXi[languageType];
    this.xiaYiBu = language.xiaYiBu[languageType];
    this.new_ZC_shuRuShouJiHao = language.new_ZC_shuRuShouJiHao[languageType];
    this.new_ZC_shuRuYanZhengMa = language.new_ZC_shuRuYanZhengMa[languageType];
    this.new_ZC_huoQuYanZhengMa = language.new_ZC_huoQuYanZhengMa[languageType];
    this.new_ZC_chongXinFaSong = language.new_ZC_chongXinFaSong[languageType];
    this.new_ZC_shuRuDianYou = language.new_ZC_shuRuDianYou[languageType];
    this.new_ZC_yiSiZhengCe = language.new_ZC_yiSiZhengCe[languageType];
    this.new_ZC_ji = language.new_ZC_ji[languageType];
    this.new_ZC_tiaoKuanXiZe = language.new_ZC_tiaoKuanXiZe[languageType];
    this.youXiaoDiZhi = language.youXiaoDiZhi[languageType];
    this.youXiaoShouJiHao = language.youXiaoShouJiHao[languageType];
    this.ZC_msgOne = language.ZC_msgOne[languageType];
    this.ZC_msgTwo = language.ZC_msgTwo[languageType];
    this.HQXXSB = language.HQXXSB[languageType];
  },
  components: { ConfirmBox },
};
</script>

<style scoped lang="scss">
.registerForm {
  width: 100%;
  padding: 0.16rem;
  box-sizing: border-box;
}
.title {
  height: 0.22rem;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.22rem;
  color: #03060d;
  text-align: left;
}
.title span {
  font-size: 0.12rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.22rem;
  color: #474747;
}
.phone,
.code,
.callPhone {
  height: 0.6rem;
  font-size: 0.16rem;
  font-family: Open Sans;
  font-weight: 400;
  line-height: 0.6rem;
  color: #051b24;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #cacbcc;
  margin-bottom: 0.24rem;
  position: relative;
}
.phone .phone-1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.phone .phone-1 i {
  width: 0.12rem;
  height: 0.12rem;
  // background: url("../assets/icon/login_icon_arrow@2x.png") no-repeat center;
  background-size: cover;
}
.phone .phone-1 b {
  width: 0.01rem;
  height: 0.16rem;
  background-color: #b1b1b3;
  margin: 0 0.1rem;
}
.phone input {
  width: 2.5rem;
}
.phone input::placeholder,
.code input::placeholder,
.callPhone input::placeholder {
  color: #b1b1b3;
}
.code {
  justify-content: space-between;
}
.code input {
  width: 2.3rem;
}
.code span {
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.28rem;
  color: #b1b1b3;
  width: 0.86rem;
  height: 0.28rem;
  border: 1px solid #b1b1b3;
  border-radius: 0.15rem;
}
.code span.active {
  border-color: #e4022b;
  color: #fff;
  background-color: #e4022b;
}
.callPhone {
  margin-bottom: 0.24rem;
}
.callPhone input {
  width: 100%;
}
input {
  border: none;
  outline: none;
}
.agreement {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 0.11rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.18rem;
  color: #03060d;
  margin-bottom: 0.12rem;
}
.agreement span {
  width: 3.2rem;
  text-align: justify;
  word-break: break-all;
}
.agreement i {
  width: 0.16rem;
  height: 0.16rem;
  border-radius: 50%;
  background: url("../assets/icon/cycle.png") no-repeat center;
  background-size: cover;
  margin-right: 0.04rem;
}
.agreement b {
  font-weight: 400;
  color: #218bce;
}
.nextBtn {
  width: 100%;
  height: 0.44rem;
  background-color: #cacbcc;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.44rem;
  color: #808080;
  opacity: 1;
  border-radius: 0.08rem;
  margin-top: 0.48rem;
}
.nextBtn.active {
  background-color: #e4022b;
  color: #fff;
}
.agreeOne.active,
.agreeTwo.active {
  background-image: url("../assets/icon/icon@2x.png") !important;
}
.phoneBox {
  height: 0;
  position: absolute;
  top: 0.4rem;
  left: 0;
  /* z-index: ; */
  background-color: #fff;
  overflow: hidden;
  transition: all 0.2s linear;
  font-size: 0.16rem;
  line-height: 25px;
}
.phoneBox p {
  height: 25px;
}
.phoneBox.active {
  height: 60px;
}
</style>
