<template>
  <div>
    <topbar :title="huiYuanDengJi" category="register"></topbar>
    <div class="progress">
      <img v-show="showCon" src="@/assets/icon/register1.png" alt="" />
      <img v-show="!showCon" src="@/assets/icon/register2.png" alt="" />
      <div class="regText">
        <p class="active">{{new_tianXieZiLiao}}</p>
        <p :class="{ active: !showCon }">{{new_wanChengDengJi}}</p>
      </div>
    </div>
    <div>
      <phone v-show="showCon" @move="nextMove"></phone>
      <password
        :email="email"
        :codeInfo="codeInfo"
        :phone="phone"
        v-show="!showCon"
      ></password>
    </div>
  </div>
</template>

<script>
import Topbar from "../../components/Topbar.vue";
import Phone from "../../components/RegisterPhone.vue";
import Password from "../../components/RegisterPas.vue";
import language from "../../assets/js/language.js";

export default {
  name: "Register",
  data() {
    return {
      progressImg: true,
      showCon: true,
      codeInfo: null, //验证过验证码的信息
      email: "",
      // 静态文字
      huiYuanDengJi: "",
      new_tianXieZiLiao:"",
      new_wanChengDengJi:"",
    };
  },
  methods: {
    nextMove(param) {
      window.dataLayer.push({
        event: "register_setup",
      });
      this.codeInfo = param.codeInfo;
      this.showCon = param.showCon;
      this.email = param.email;
      this.phone=param.phone
    },
  },
  created() {
    var languageType;
    if (this.$store.state.language == 1) {
      languageType = "chinese";
    } else {
      languageType = "english";
    }
    this.huiYuanDengJi = language.huiYuanDengJi[languageType];
    this.new_tianXieZiLiao=language.new_tianXieZiLiao[languageType];
    this.new_wanChengDengJi=language.new_wanChengDengJi[languageType];
  },
  components: {
    Topbar,
    Phone,
    Password,
  },
};
</script>

<style scoped lang="scss">
.progress {
  width: 100%;
  height: 0.73rem;
  background: #f2f3f5;
  opacity: 1;
  position: relative;
}
.progress img {
  width: 1.7rem;
  position: absolute;
  margin: 0;
  top: 0.17rem;
  left: 50%;
  transform: translateX(-50%);
}
.registerForm {
  width: 100%;
  padding: 0.16rem;
  box-sizing: border-box;
}
.regText {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 0.12rem;
  color: #808080;
  padding: 0 0.9rem;
  box-sizing: border-box;
  padding-top: 0.45rem;
}
.regText p.active {
  color: #e4022b;
}
</style>
