<template>
  <div class="confirmBox">
    <div class="content">
      <i class="logo"></i>
      <p class="title" v-if="showSolt">
        <slot name="title"></slot>
      </p>
      <p class="title" v-if="title" :style="{ fontSize: size + 'px' }">
        {{ title }}
      </p>
      <p class="title" v-if="subTitleShow" :style="{ fontSize: size + 'px' }">
        {{ subTitle }}
      </p>
      <div class="billNum" v-show="showBill">
        <p class="billTitle">
          {{ $store.state.language == 1 ? "取餐號碼" : "Order Number" }}
        </p>
        <p class="bill">{{ shopBillNo }}</p>
      </div>
      <p class="time">{{ content }}</p>
      <div class="btn">
        <span v-if="show" @click="closeBox">{{ cancelBtn }}</span>
        <span @click="finish">{{ finishBtn }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ConfirmBox",
  props: {
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
    subTitleShow: {
      type: Boolean,
      default: false,
    },
    content: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: false,
    },
    shopBillNo: {
      type: String,
      default: "",
    },
    showBill: {
      type: Boolean,
      default: false,
    },
    finishBtn: {
      type: String,
      default: "確定",
    },
    cancelBtn: {
      type: String,
      default: "取消",
    },
    size: {
      type: Number,
      default: 18,
    },
    showSolt: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeBox() {
      this.$emit("closeBox");
    },
    finish() {
      this.$emit("finish");
    },
  },
};
</script>
<style scoped>
.confirmBox {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99999999999;
}
.content {
  width: 3rem;
  background: #ffffff;
  border-radius: 0.12rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}
.logo {
  /* width: 1.56rem; */
  display: block;
  background: url("../../assets/icon/mainT.png") no-repeat center;
  background-size: 1.56rem 0.1rem;
  height: 0.1rem;
  margin-bottom: 0.2rem;
}
.time {
  font-size: 14px;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 20px;
  color: #474747;
  margin: 0.08rem 0.16rem 0.16rem;
}
.title {
  font-size: 18px;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 25px;
  color: #03060d;
  padding: 0 0.16rem;
  box-sizing: border-box;
}
.btn {
  height: 0.54rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 500;
  line-height: 0.54rem;
  color: #b1b1b3;
  border-top: 1px solid #cacbcc;
}
.btn span {
  width: 100%;
  text-align: center;
}
.btn span:first-child {
  border-right: 1px solid #cacbcc;
}
.btn span:last-child {
  color: #e4022b;
}
.billNum {
  width: 1.84rem;
  height: 1.24rem;
  background: #ffffff;
  border: 1px solid #cacbcc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.09);
  border-radius: 8px;
  color: #e4022b;
  margin: 0.16rem auto;
}
.billTitle {
  height: 0.25rem;
  font-size: 0.18rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.25rem;
  color: #e4022b;
  text-align: center;
  margin-top: 0.16rem;
}
.bill {
  height: 0.67rem;
  font-size: 0.48rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.67rem;
  color: #e4022b;
}
.orderAlert {
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.2rem;
  color: #474747;
  padding: 0 0.24rem;
  box-sizing: border-box;
}
.orderAlert p {
  text-align: left;
  margin-top: 0.04rem;
}
.alertTitle {
  margin-bottom: 0.08rem;
  color: #03060d;
  font-size: 0.16rem;
}
.orderAlert p span {
  width: 0.05rem;
  height: 0.1rem;
  display: inline-block;
  border-right: 0.01rem solid #e4022b;
  border-bottom: 0.01rem solid #e4022b;
  transform: rotate(45deg);
}
</style>