<template>
  <div>
    <div class="registerForm">
      <p class="title">{{ sheZhiMiMa }}</p>
      <div class="inputBox">
        <input
          v-model.trim="password"
          @input="changeBtn"
          :type="typeOne"
          :placeholder="miMa"
        />
        <span
          @click="showPas(1)"
          :class="{ active: typeOne == 'password' }"
        ></span>
      </div>
      <p class="warn">{{ miMaGuiZe }}</p>
      <p class="title">{{ new_ZC_queRenMiMa }}</p>
      <div class="inputBox">
        <input
          v-model.trim="passwordAgain"
          :type="typeTwo"
          :placeholder="new_ZCzaiCiShuRuMiMa"
          @input="changeBtn"
        />
        <span
          @click="showPas(2)"
          :class="{ active: typeTwo == 'password' }"
        ></span>
      </div>
      <div class="finishBtn" :class="{ active: btnShow }" @click="finish">
        {{ wanCheng }}
      </div>
    </div>

    <confim
      @closeCon="close"
      v-show="showConfim"
      :title="welcomeKFCHuiYuan"
      :btnText="kaiShiDianCan"
      :show="showConfim"
    ></confim>
  </div>
</template>
<script>
import Confim from "./common/Confim.vue";
import language from "../assets/js/language.js";
import { postPaas, postDC } from "../assets/utils/request";

export default {
  name: "RegisterPas",
  data() {
    return {
      typeOne: "password",
      typeTwo: "password",
      btnShow: false,
      password: "",
      passwordAgain: "",
      showConfim: false,
      registerInfo: null,
      // 静态文字
      miMa: "",
      sheZhiMiMa: "",
      miMaGuiZe: "",
      wanCheng: "",
      welcomeKFCHuiYuan: "",
      kaiShiDianCan: "",
      new_ZC_queRenMiMa: "",
      new_ZCzaiCiShuRuMiMa: "",
      HQXXSB: "",
      LCSRMMBYZ: "",
    };
  },
  props: {
    codeInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    email: {
      type: String,
      default: "",
    },
    phone: {
      type: String,
    },
  },
  methods: {
    showPas(type) {
      if (type == 1) {
        this.typeOne = this.typeOne == "password" ? "text" : "password";
      } else {
        this.typeTwo = this.typeTwo == "password" ? "text" : "password";
      }
    },
    changeBtn() {
      if (this.password.length < 6 || this.passwordAgain.length < 6) {
        return (this.btnShow = false);
      }
      this.btnShow = true;
    },
    async finish() {
      if (!this.btnShow) return;
      if (this.password != this.passwordAgain) {
        return this.$toast({ message: this.LCSRMMBYZ, duration: 1000 });
      }
      var params = {
        actionName: "candao.member.registerComplete",
        content: {
          registerCode: this.codeInfo.registerCode, //
          password: this.password,
          email: this.email,
        },
      };
      let result = await postPaas("UserUnify", params);
      if (result.status == 1) {
        this.registerInfo = result.data;
        this.loginAutomatic();
        // this.showConfim = true;
      } else {
        this.$toast(this.HQXXSB);
      }
    },
    async close() {
      window.dataLayer.push({
        event: "register_success",
      });
      this.$router.push("/");
      this.showConfim = false;
    },
    // 自动登录
    async loginAutomatic() {
      let paramsOne = {
        actionName: "candao.user.getUserByExtUserId",
        content: {
          extUserId: this.registerInfo.userId,
          phone: this.phone,
        },
      };
      // 获取secretKey
      let canDaoUser = await postDC("Action", paramsOne);
      if (canDaoUser.status != 1) {
        this.$toast(this.HQXXSB);
        return false;
      }
      var paramsTwo = {
        actionName: "candao.member.bindAccessCode",
        secretKey: canDaoUser.data.secretKey,
        content: {
          accessCode: this.registerInfo.accessCode,
          deviceId: "iPhone",
        },
      };
      // 绑定accessCode
      let bindCode = await postPaas("UserUnify", paramsTwo);
      if (bindCode.status == 1) {
        window.dataLayer.push({
          event: "login",
        });
      } else {
        this.$toast(this.HQXXSB);
        return false;
      }
      var params = {
        actionName: "candao.member.userProfileGet",
        content: {},
      };
      let result = await postPaas("UserUnify", params);
      if (result.status == 1) {
        this.$store.commit("setUserInfo", result.data);
        this.showConfim = true;
        return true;
      } else {
        this.$toast(this.HQXXSB);
        return false;
      }
    },
    setLanguage() {
      var languageType;
      if (this.$store.state.language == 1) {
        languageType = "chinese";
      } else {
        languageType = "english";
      }
      this.miMa = language.miMa[languageType];
      this.sheZhiMiMa = language.sheZhiMiMa[languageType];
      this.miMaGuiZe = language.miMaGuiZe[languageType];
      this.wanCheng = language.wanCheng[languageType];
      this.welcomeKFCHuiYuan = language.welcomeKFCHuiYuan[languageType];
      this.kaiShiDianCan = language.kaiShiDianCan[languageType];
      this.new_ZC_queRenMiMa = language.new_ZC_queRenMiMa[languageType];
      this.new_ZCzaiCiShuRuMiMa = language.new_ZCzaiCiShuRuMiMa[languageType];
      this.HQXXSB = language.HQXXSB[languageType];
      this.LCSRMMBYZ = language.LCSRMMBYZ[languageType];
    },
  },
  created() {
    window.dataLayer.push({
      event: "view_pw_mismatch",
    });
    this.setLanguage();
  },
  components: {
    Confim,
  },
};
</script>
<style scoped>
.registerForm {
  width: 100%;
  padding: 0.16rem;
  box-sizing: border-box;
}
.title {
  height: 0.22rem;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 500;
  line-height: 0.22rem;
  color: #03060d;
  text-align: left;
}
.inputBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 0.6rem;
  border-bottom: 1px solid #cacbcc;
}
.inputBox span {
  width: 0.28rem;
  height: 0.28rem;
  background: url("../assets/icon/showpas.png") no-repeat center;
  background-size: cover;
}
.inputBox span.active {
  background: url("../assets/icon/login_icon_hide@2x.png") no-repeat center;

  background-size: cover;
}
input {
  width: 3rem;
  outline: none;
  border: none;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #b1b1b3;
}
.warn {
  width: 100%;
  text-align: left;
  font-size: 0.12rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.18rem;
  color: #474747;
  margin: 0.04rem 0 0.24rem;
}
.finishBtn {
  width: 100%;
  height: 0.44rem;
  border-radius: 0.08rem;
  line-height: 0.44rem;
  text-align: center;
  font-weight: 500;
  font-size: 0.16rem;
  font-family: Roboto;
  background-color: #bbb;
  margin-top: 0.48rem;
  color: #808080;
}
.finishBtn.active {
  background-color: #e4022b;
  color: #fff;
}
</style>